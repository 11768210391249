import { getPhoneNumber } from '../../utils/formatters';

export const useListContent = (data = {}) => {
  const {
    name_agency,
    transmission_code,
    phone_number,
    email,
    follow_up_prodcedure,
    procedure_email,
    procedure_url,
    address
  } = data;

  return [
    {
      label: 'payingagencies.form.name_agency',
      span: 1,
      content: name_agency || '-'
    },
    ...(data?.type === 'AMO'
      ? [
          {
            label: 'payingagencies.form.transmission_code',
            span: 1,
            content: transmission_code
              ? transmission_code.replace(/^(\d{2})(\d{3})(\d{4})$/, '$1 $2 $3')
              : '-'
          }
        ]
      : []),
    ...(data?.type === 'AMC'
      ? [
          {
            label: 'payingagencies.form.transmission_code',
            span: 1,
            content: transmission_code || '-'
          }
        ]
      : []),
    {
      label: 'payingagencies.form.phone_number',
      span: 1,
      content:
        phone_number && phone_number.number ? getPhoneNumber(phone_number) : '-'
    },
    {
      label: 'payingagencies.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'payingagencies.form.follow_up_prodcedure',
      span: 1,
      content: follow_up_prodcedure || '-'
    },
    ...(procedure_email
      ? [
          {
            label: 'payingagencies.form.procedure_email',
            span: 1,
            content: procedure_email || '-'
          }
        ]
      : []),
    ...(procedure_url
      ? [
          {
            label: 'payingagencies.form.procedure_url',
            span: 1,
            content: procedure_url || '-'
          }
        ]
      : []),
    ...(address && address?.street
      ? [
          {
            label: 'payingagencies.form.address.street',
            span: 1,
            content:
              address && address?.street && address?.number
                ? `${address.number}, ${address.street}`
                : '-'
          },
          {
            label: 'payingagencies.form.address.postcode',
            span: 1,
            content: address?.postal_code || '-'
          },
          {
            label: 'payingagencies.form.address.additional',
            span: 1,
            content: address?.additional || '-'
          },
          {
            label: 'payingagencies.form.address.city',
            span: 1,
            content: address?.city || '-'
          }
        ]
      : [])
  ];
};

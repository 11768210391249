import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  pathSearches,
  routes,
  subRoutes
} from '../../utils/constants/adminRoutes';
import navMenuLogos from '../../utils/constants/navMenuLogos';

export const renderMenuItems = () => {
  const { t } = useTranslation();
  return Object.entries(routes).map(([menuItem, p]) => {
    if (subRoutes[menuItem]) {
      return {
        key: p,
        icon: navMenuLogos[menuItem],
        label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
        children: Object.entries(subRoutes[menuItem]).map(
          ([subMenuItem, subPath]) => ({
            key: subPath,
            disabled: subPath === '',
            label: (
              <NavLink
                to={`${p}${subPath}${
                  pathSearches[subMenuItem] ? pathSearches[subMenuItem] : ''
                }`}
              >
                <span>
                  {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                </span>
              </NavLink>
            )
          })
        )
      };
    }

    if (menuItem !== 'EVENTS') {
      return {
        key: p,
        disabled: p === '',
        label: (
          <NavLink
            to={`${p}${pathSearches[menuItem] ? pathSearches[menuItem] : ''}`}
          >
            {navMenuLogos[menuItem]}
            <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
          </NavLink>
        )
      };
    }

    return false;
  });
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Input, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { usePwdPattern } from '../../utils/pwdPattern';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';

/**
 * Component for rendering the password change form.
 * This component allows users to change their password using a token received in the password reset email.
 * @component
 * @param {string} token - The token received in the password reset email.
 * @param {Function} switchForm - Function to switch to another form (e.g., login form).
 * @returns {JSX.Element} ChangePwdForm component.
 */
const ChangePwdForm = ({ token, switchForm }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const changePwd = async (body) => {
    setLoading(true);
    try {
      await dispatchAPI('POST', { url: `reset-pwd/${token}`, body });
      message.success(t('login.passwordChanged'));
      switchForm('login');
      navigate(outOfNavRoutes.LOGIN);
    } catch (e) {
      message.error(t('login.wrongToken'));
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await changePwd(values);
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: t('login.pwdMissing') },
          ...usePwdPattern()
        ]}
        hasFeedback
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.password')}
        />
      </Form.Item>
      <Form.Item
        dependencies={['password']}
        hasFeedback
        name="confirm"
        rules={[
          {
            required: true,
            message: t('login.pwdMissing')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(t('login.pwdNotMatching'));
            }
          })
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={t('login.confirmPassword')}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.resetPwd')}
        </Button>
      </Form.Item>
    </Form>
  );
};

ChangePwdForm.propTypes = {
  token: PropTypes.string,
  switchForm: PropTypes.func.isRequired
};

ChangePwdForm.defaultProps = {
  token: null
};

export default ChangePwdForm;

import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form, Input } from 'antd';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

export const usePayingAgencyFields = (
  purpose,
  formValues,
  transmissionCodeRule,
  setTransmissionCodeRule
) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [Enums, setEnums] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/payingagency/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleTypeChange = (value) => {
    if (value === 'AMC' || value === 'AMC_NO') {
      setTransmissionCodeRule([{ required: true }, { len: 8 }]);
    } else {
      setTransmissionCodeRule([{ required: true }, { len: 9 }]);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const payingAgencyfields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isLoading} onChange={handleTypeChange}>
          {(Enums?.types || []).map((type) => (
            <Select.Option key={type} value={type}>
              {t(`enums.${type}`)}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['name_agency'],
      rules: [{ required: true }]
    },
    {
      name: ['email']
    },
    {
      name: ['transmission_code'],
      rules: transmissionCodeRule
    },
    {
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select loading={isLoading}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['follow_up_prodcedure'],
      input: (
        <Select loading={isLoading}>
          {(Enums?.procedures || []).map((procedure) => (
            <Select.Option key={procedure} value={procedure}>
              {t(`enums.${procedure}`)}
            </Select.Option>
          ))}
        </Select>
      )
    },
    ...(formValues?.follow_up_prodcedure === 'COURRIER POSTAL'
      ? [
          {
            label: 'address.number',
            name: ['address', 'number']
          },
          {
            label: 'address.street',
            name: ['address', 'street']
          },
          {
            label: 'address.additional',
            name: ['address', 'additional']
          },
          {
            label: 'address.postcode',
            name: ['address', 'postal_code']
          },
          {
            label: 'address.city',
            name: ['address', 'city']
          }
        ]
      : []),
    ...(formValues?.follow_up_prodcedure === 'FORMULAIRE EN LIGNE'
      ? [
          {
            label: 'procedure_url',
            name: ['procedure_url']
          }
        ]
      : []),
    ...(formValues?.follow_up_prodcedure === 'MAIL'
      ? [
          {
            label: 'procedure_email',
            name: ['procedure_email']
          }
        ]
      : [])
  ];

  return {
    payingAgencyfields
  };
};

import { useState, useEffect, useCallback } from 'react';
import { Select, Radio, Form, Input, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { AddIcon } from '../../utils/constants/customIcons';
import { noLabelLayout } from '../../utils/constants/formLayout';

const { Option } = Select;

/**
 * Custom hook that generates form fields configuration for managing centers.
 * This hook provides form field definitions for creating or updating center information.
 * @hook
 * @param {Function} openDrawer - Function to open a drawer for adding or editing prescribers.
 * @param {Array<Object>} prescribers - Array of prescriber objects.
 * @returns {Object} An object containing form field configurations for managing centers.
 */
export const useCenterFields = (openDrawer, prescribers) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [soldtos, setSoldTos] = useState([]);
  const [enums, setEnums] = useState({});
  const { user } = useAuthContext();
  const { id } = useParams();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const onsearch = (input, option) => {
    const optionText = option.props.children;
    const normalizedText = optionText.toString().toLowerCase();
    return normalizedText.includes(input.toLowerCase());
  };

  const getSoldTo = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/centers' });
      const soldToValues = data.map((item) => {
        if (item._id !== id) {
          return item.soldto;
        }
        return [];
      });

      setSoldTos(soldToValues);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const validateSoldTo = (_, value) => {
    const isSoldToExists = soldtos?.some((s) => s === value);
    if (isSoldToExists) {
      return Promise.reject(new Error(t('Le soldTo existe déjà')));
    }
    return Promise.resolve();
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/centers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getSoldTo();
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const centerFields = [
    {
      name: ['soldto'],
      rules: [
        { required: true },
        {
          validator: validateSoldTo
        }
      ]
    },
    {
      name: ['type'],
      rules: [{ required: true }],
      valuePropName: 'value',
      input: (
        <Radio.Group>
          {(enums?.types || []).map((type) => (
            <Radio key={type} value={type}>
              {t(`enums.${type}`)}
            </Radio>
          ))}
        </Radio.Group>
      )
    },
    {
      name: ['center_name'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'number']
    },
    {
      name: ['address', 'street']
    },
    {
      name: ['address', 'postal_code']
    },
    {
      name: ['address', 'city']
    },
    {
      name: ['sales_representative']
    },
    {
      name: ['regional_director']
    },
    {
      name: ['finess_number'],
      rules: [{ len: 9 }]
    },
    {
      name: ['prescribers'],
      input: (
        <Select
          loading={isLoading}
          mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(prescribers || []).map((prescriber) => (
            <Option key={prescriber._id} value={prescriber._id}>
              {prescriber.last_name} {prescriber.first_name}
              {prescriber?.health_insurance_id.length > 1
                ? ` / ${t('prescribers.form.health_insurance_id')} : ${
                    prescriber?.health_insurance_id
                  }`
                : ''}
              {prescriber?.rpps_number
                ? ` / ${t('prescribers.form.rpps_number')} : ${
                    prescriber?.rpps_number
                  }`
                : ''}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['new_center'],
      noLabel: true,
      input: (
        <Form.Item {...noLabelLayout}>
          <Button type="primary" onClick={() => openDrawer('prescribers')}>
            {t('centers.form.addPrescriber')}
            <AddIcon />
          </Button>
        </Form.Item>
      )
    },
    {
      name: ['contacts'],
      input: (
        <Form.List name="contacts" initialValue={[]}>
          {(fieldsContact, { add, remove }) => (
            <>
              {fieldsContact.map((field, index) => (
                <div key={field.key}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'last_name']}
                    rules={[{ required: true }]}
                  >
                    <Input
                      disabled={!isAdmin}
                      placeholder={`${t('centers.form.contact.last_name')} ${
                        index + 1
                      }`}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name]}
                    name={[field.name, 'first_name']}
                    rules={[{ required: true }]}
                  >
                    <Input
                      disabled={!isAdmin}
                      placeholder={`${t('centers.form.contact.first_name')} ${
                        index + 1
                      }`}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name]}
                    name={[field.name, 'position']}
                  >
                    <Input
                      disabled={!isAdmin}
                      placeholder={`${t('centers.form.contact.position')} ${
                        index + 1
                      }`}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name]}
                    name={[field.name, 'phone_number']}
                  >
                    <Input.Group compact>
                      <Form.Item
                        noStyle
                        name={[field.name, 'phone_number', 'country_code']}
                        initialValue="+33"
                      >
                        <Select>
                          <Select.Option value="+33">+33</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={[field.name, 'phone_number', 'number']}
                      >
                        <Input />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name]}
                    name={[field.name, 'email']}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={`${t('centers.form.contact.email')} ${
                        index + 1
                      }`}
                    />
                  </Form.Item>
                  {index !== 0 && (
                    <CloseCircleOutlined
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        fontSize: '16px',
                        color: 'red',
                        cursor: 'pointer'
                      }}
                      onClick={() => isAdmin && remove(field.name)}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => isAdmin && add()}>
                  {t('centers.form.contact.addContact')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];

  return {
    centerFields
  };
};

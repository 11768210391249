/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { Button, Form, Input, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { usePwdPattern } from '../../utils/pwdPattern';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import { ContentCustom, PageHeaderCustom } from '../../components';

/**
 * Component for updating user password.
 * @returns {JSX.Element} - JSX for updating user password.
 */

const UpdatePwd = () => {
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const updatePwd = async (body) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', { url: `/users/change-password`, body });
      message.success(t('profile.message.pwdUpdated'));
      form.resetFields();
      navigate(-1);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (values) => {
    await updatePwd({
      password: values.password,
      new_password: values.new_password
    });
  };

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('profile.settings.modifyPwd')} />

      <Form {...formItemLayout} onFinish={handleSubmit}>
        <Form.Item
          name="password"
          label={t('profile.form.password')}
          rules={[{ required: true, message: t('profile.form.pwdMissing') }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="new_password"
          label={t('profile.form.new_password')}
          rules={[
            { required: true, message: t('profile.form.pwdMissing') },
            ...usePwdPattern()
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          dependencies={['new_password']}
          hasFeedback
          name="confirm"
          label={t('profile.form.confirm_password')}
          rules={[
            {
              required: true,
              message: t('profile.form.pwdMissing')
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('login.pwdNotMatching'));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Row justify="end">
            <Button type="link" danger onClick={() => navigate(-1)}>
              {t('buttons.cancel')}
              <CloseOutlined />
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {t('buttons.save')}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </ContentCustom>
  );
};

export default UpdatePwd;

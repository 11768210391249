import { Route, Routes } from 'react-router-dom';
import { ListPrescribers } from './ListPrescribers';
import { CreateUpdatePrescriber } from './CreateUpdatePrescriber';
import { ShowPrescriber } from './ShowPrescriber';
import { Exception } from '../../components';

export const PrescriberRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdatePrescriber purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdatePrescriber purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowPrescriber />} />
    <Route index element={<ListPrescribers />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCenterFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import CenterPrecriberDrawer from './CenterPrescriberDrawer';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for creating or updating a center.
 * @param {Object} props - The props object.
 * @param {string} props.purpose - The purpose of the operation (e.g., 'create' or 'update').
 * @returns {JSX.Element} - JSX for creating or updating a center.
 */
export const CreateUpdateCenter = ({ purpose }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [prescribers, setPrescribers] = useState([]);
  const [centers, setCenters] = useState([]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const { centerFields } = useCenterFields(openDrawer, prescribers);

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const getPrescribers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prescribers' });

      setPrescribers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCenters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/centers?populate=prescribers'
      });
      setCenters(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getPrescribers();
      await getCenters();
    })();
  }, []);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    }
  };
  return (
    <>
      {isDrawerOpen && (
        <CenterPrecriberDrawer
          isDrawerOpen={isDrawerOpen}
          purpose="create"
          closeDrawer={closeDrawer}
          getPrescribers={getPrescribers}
          centers={centers}
        />
      )}
      <CreateUpdateContainer
        fields={centerFields}
        loadingFields
        purpose={purpose}
        baseUrl="centers"
        resource="centers"
        withFilesManager={false}
        config={config}
        needFormData={false}
        populate="contacts"
      />
    </>
  );
};

/**
 * PropTypes for CreateUpdateCenter component.
 * @type {Object}
 * @property {string} purpose - The purpose of the operation (e.g., 'create' or 'update').
 */

CreateUpdateCenter.propTypes = {
  purpose: PropTypes.string.isRequired
};

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from './prescriberCenterFields';

/**
 * A drawer component for managing center prescribers.
 * This component displays a drawer with a form for creating or updating center prescribers.
 * @component
 * @param {boolean} isDrawerOpen - A boolean indicating whether the drawer is open.
 * @param {string} purpose - The purpose of the drawer, either 'create' or 'update'.
 * @param {Function} closeDrawer - A function to close the drawer.
 * @param {Function} getPrescribers - A function to fetch prescribers data.
 * @param {Array<Object>} centers - An array of center objects.
 * @returns {JSX.Element} The JSX element representing the CenterPrescriberDrawer component.
 */
const CenterPrescriberDrawer = ({
  isDrawerOpen,
  purpose,
  closeDrawer,
  getPrescribers,
  centers
}) => {
  const { t } = useTranslation();

  const { fields } = useFields(purpose, centers);

  const customNavigate = () => {
    closeDrawer();
    getPrescribers();
  };

  return (
    <Drawer
      centered
      title={t(`prescribers.titles.${purpose}`)}
      open={isDrawerOpen}
      onClose={closeDrawer}
      width={1200}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        purpose={purpose}
        baseUrl="prescribers"
        resource="prescribers"
        isOverlay
        customNavigate={customNavigate}
        withHeader={false}
        withFilesManager={false}
      />
    </Drawer>
  );
};

CenterPrescriberDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  purpose: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
  getPrescribers: PropTypes.func,
  centers: PropTypes.arrayOf(PropTypes.shape({}))
};

CenterPrescriberDrawer.defaultProps = {
  purpose: null,
  getPrescribers: null,
  centers: []
};

export default CenterPrescriberDrawer;

import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * Component for creating or updating a prescriber.
 * @param {Object} props - The props object.
 * @param {string} props.purpose - The purpose of the operation (e.g., 'create' or 'update').
 * @returns {JSX.Element} - JSX for creating or updating a prescriber.
 */

export const CreateUpdatePrescriber = ({ purpose }) => {
  const { fields } = useFields(purpose);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields
      purpose={purpose}
      baseUrl="prescribers"
      resource="prescribers"
      withFilesManager={false}
      config={config}
    />
  );
};

/**
 * PropTypes for CreateUpdatePrescriber component.
 * @type {Object}
 * @property {string} purpose - The purpose of the operation (e.g., 'create' or 'update').
 */

CreateUpdatePrescriber.propTypes = {
  purpose: PropTypes.string.isRequired
};

import { getPhoneNumber } from '../../utils/formatters';

export const useListContent = (data = {}) => {
  const {
    first_name,
    last_name,
    health_insurance_id,
    rpps_number,
    email,
    phone_number
  } = data;

  return [
    {
      label: 'prescribers.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'prescribers.form.first_name',
      span: 2,
      content: first_name || '-'
    },
    {
      label: 'prescribers.form.health_insurance_id',
      span: 1,
      content:
        health_insurance_id?.map((insurance_id) => insurance_id).join('/') ||
        '-'
    },
    {
      label: 'prescribers.form.rpps_number',
      span: 1,
      content: rpps_number || '-'
    },
    {
      label: 'prescribers.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'prescribers.form.phone_number',
      span: 2,
      content:
        phone_number && phone_number.number ? getPhoneNumber(phone_number) : '-'
    }
  ];
};

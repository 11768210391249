import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('settings.title')} />
    </ContentCustom>
  );
};

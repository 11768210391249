import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

/**
 * Custom hook that generates column configuration for center data table.
 * This hook provides column definitions for displaying center-related information in a table.
 * @hook
 * @returns {Array} An array of column configurations for the center data table.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const [lastComment, setLastComment] = useState(null);

  const handleMouseEnter = (comment) => {
    setLastComment(comment);
  };

  return [
    {
      title: t('centers.form.soldto'),
      key: 'soldto',
      dataIndex: 'soldto',
      sorter: true
    },
    {
      title: t('centers.form.finess_number'),
      key: 'finess_number',
      dataIndex: 'finess_number',
      sorter: true
    },
    {
      title: t('centers.form.center_name'),
      key: 'center_name',
      dataIndex: 'center_name',
      sorter: true
    },
    {
      title: t('centers.form.address.postal_code'),
      key: 'address.postal_code',
      dataIndex: 'address',
      render: (address) => (address?.postal_code ? address.postal_code : '-'),
      sorter: true
    },
    {
      title: t('centers.form.address.city'),
      key: 'address.city',
      dataIndex: 'address',
      render: (address) => (address?.city ? address.city : '-'),
      sorter: true
    },
    {
      title: t('centers.form.sales_representative'),
      key: 'sales_representative',
      dataIndex: 'sales_representative',
      sorter: true
    },
    {
      title: t('centers.form.regional_director'),
      key: 'regional_director',
      dataIndex: 'regional_director',
      sorter: true
    },
    {
      title: t('centers.form.last_comment'),
      key: 'comments',
      dataIndex: 'comments',
      render: (comments) =>
        comments &&
        comments.length > 0 && (
          <div
            onMouseEnter={() => handleMouseEnter(comments[comments.length - 1])}
          >
            <Popover
              title="Dernier commentaire"
              content={lastComment && lastComment.content}
            >
              <ExclamationCircleOutlined style={{ fontSize: 18 }} />
            </Popover>
          </div>
        )
    }
  ];
};

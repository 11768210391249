import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Col,
  message as antdMessage
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  MailOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * Component for displaying user details.
 * @returns {JSX.Element} - JSX for displaying user details.
 */

export const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user: userInfo } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const listContent = useListContent(user);
  const isSuperAdmin = userInfo?.role === 'admins:SUPER-ADMIN';

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const sendPasswordEmail = async () => {
    try {
      await dispatchAPI('PATCH', { url: `/users/send_email/${id}` });
      antdMessage.success(t('users.message.email_sent'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/users/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={`${user?.last_name} ${user?.first_name}`}
        extra={
          <>
            {isSuperAdmin && (
              <Popconfirm
                title={t('datatable.column.action.send_email_pwd.title')}
                okText={t('datatable.column.action.send_email_pwd.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.send_email_pwd.cancel')}
                onConfirm={sendPasswordEmail}
                icon={<WarningOutlined />}
              >
                <Button type="primary">
                  {`${t('buttons.send_email_pwd')} `}
                  <MailOutlined />
                </Button>
              </Popconfirm>
            )}
            <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteUser}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />

      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent} translate />
          </Skeleton>
        </Col>
      </Row>
    </ContentCustom>
  );
};

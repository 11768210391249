import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';

/**
 * Component for listing centers.
 * This component renders a list of centers using the ListResource component.
 * @component
 * @returns {JSX.Element} The JSX element representing the list of centers.
 */
export const ListCenters = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="centers"
      columns={columns}
      headers={headers}
      resourceModelName="centers"
    />
  );
};

import { useTranslation } from 'react-i18next';

/**
 * Custom hook that generates a content list for center data.
 * This hook provides an array of objects representing the content of center data,
 * suitable for displaying in a list or details view.
 * @hook
 * @param {Object} data - Data related to the center.
 * @param {string} data.soldto - The sold-to code of the center.
 * @param {string} data.type - The type of the center.
 * @param {string} data.finess_number - The FINESS number of the center.
 * @param {string} data.center_name - The name of the center.
 * @param {Object} data.address - Object containing address information of the center.
 * @param {string} data.address.street - The street address of the center.
 * @param {string} data.address.number - The house number of the center.
 * @param {string} data.address.postal_code - The postal code of the center's address.
 * @param {string} data.address.city - The city of the center's address.
 * @param {string} data.sales_representative - The sales representative of the center.
 * @param {string} data.regional_director - The regional director of the center.
 * @returns {Array} A content list for the center data.
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const translationKey = 'centers.form';
  const {
    soldto,
    type,
    finess_number,
    center_name,
    address,
    regional_director,
    sales_representative
  } = data;

  return [
    {
      label: t(`${translationKey}.soldto`),
      span: 1,
      content: soldto || '-'
    },
    {
      label: t(`${translationKey}.center_name`),
      span: 1,
      content: center_name || '-'
    },
    {
      label: t(`${translationKey}.type`),
      span: 2,
      content: type ? t(`${translationKey}.enums.${type}`) : '-'
    },
    {
      label: t(`${translationKey}.finess_number`),
      span: 1,
      content: finess_number || '-'
    },
    {
      label: t(`${translationKey}.address.street`),
      span: 1,
      content:
        address && address?.street && address?.number
          ? `${address.number}, ${address.street}`
          : '-'
    },
    {
      label: t(`${translationKey}.address.postal_code`),
      span: 1,
      content: address?.postal_code || '-'
    },
    {
      label: t(`${translationKey}.address.city`),
      span: 1,
      content: address?.city || '-'
    },
    {
      label: t(`${translationKey}.sales_representative`),
      span: 1,
      content: sales_representative || '-'
    },
    {
      label: t(`${translationKey}.regional_director`),
      span: 1,
      content: regional_director || '-'
    }
  ];
};

import { createGlobalStyle } from 'styled-components';
import '../SCSS/user/index.scss';

export const UserTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #A25384;
    --addColor: var(--secondaryColor);
    --userColor: var(--primaryColor);
    --identificationColor: #30972E;
    --administrationColor: #629AD2;
    --technicalColor: #d19721;
    --billingColor: #FF483C;
    --websitesColor: #62a341;
    --interventionColor: #B533A8;
    --statsColor: #314375;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #eaf8f7;
    --secondaryColor: #32B6B2;
    --disabledColor: #d9d9d9;
    --bodyBackground: #fafafa;
    --textColor: #3F3F3F;
    --textColorSecondary: #796477;
    --textColorInvert: #fff;
    --itemActiveBackground: var(--primaryHover);
    --itemHoverBackground: #eaf8f7;
    --borderColor: #d9d9d9;
    --borderPrimaryColor: rgba(162, 83, 132, 0.3);
    --bgPrimaryColor: rgba(162, 83, 132, 0.1);
    --contrastBackground: #eaf8f7;
    --errorColor: #e31919;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;

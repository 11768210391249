import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

/**
 * Hook for defining columns configuration for the mails table.
 * @returns {Object} Columns configuration.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('mails.columns.error'),
      key: 'error',
      render: (data) =>
        data?.error ? (
          <CloseOutlined style={{ color: 'red' }} />
        ) : (
          <CheckOutlined style={{ color: 'green' }} />
        )
    },
    {
      title: t('mails.columns.from'),
      key: 'from',
      dataIndex: 'from',
      sorter: true,
      render: (from) => from && from.email
    },
    {
      title: t('mails.columns.to'),
      key: 'to',
      render: (data) =>
        data && (data.to || []).map((item) => <p>{item.email}</p>)
    },
    {
      title: t('mails.columns.cc'),
      key: 'cc',
      render: (data) =>
        data && (data.cc || []).map((item) => <p>{item.email}</p>)
    },
    {
      title: t('mails.columns.subject'),
      key: 'subject',
      dataIndex: 'subject',
      render: (subject) => subject && subject
    },
    {
      title: t('mails.columns.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) =>
        (created_at && moment(created_at).format('DD/MM/YYYY HH:mm')) || '-',
      sorter: true
    }
  ];

  return { columns };
};

import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Row, Spin, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  FolderOpenFilled,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import useFields from './fields';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';

/**
 * Component for updating user profile details.
 * @returns {JSX.Element} - JSX for updating user profile details.
 */

const { Dragger } = Upload;

const UpdateProfile = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { fields } = useFields();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/users/${user?._id}` });
      form.setFieldsValue({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      });
      setUser(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, [fetchUser]);

  // This function convert the PDF to base64 format
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const updateProfile = async (body) => {
    try {
      let photo;
      if (fileList.length > 0) {
        photo = await fileToBase64(fileList[0]);
      }
      await dispatchAPI('PATCH', {
        url: `/users/${user?._id}`,
        body: { ...body, photo }
      });
      await fetchUser();
      navigate(outOfNavRoutes.PROFILE);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleSubmit = async (values) => {
    await updateProfile(values);
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
      } else {
        message('Not a PNG or JPG file.');
        return true;
      }
      return false;
    },
    fileList
  };

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('profile.title')} />
      <Spin spinning={isLoading}>
        <Form form={form} onFinish={handleSubmit} {...formItemLayout}>
          <Form.Item {...formItemLayout} name="photo" label="Avatar">
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <FolderOpenFilled style={{ color: 'var(--primaryColor)' }} />
              </p>
            </Dragger>
          </Form.Item>
          {fields.map((field) => generateFields('users', field))}
          <Form.Item {...tailFormItemLayout}>
            <Row justify="end">
              <Button
                style={{ margin: '0 8px' }}
                type="link"
                danger
                onClick={() => navigate(-1)}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button type="primary" htmlType="submit">
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </ContentCustom>
  );
};

export default UpdateProfile;

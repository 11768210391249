import PropTypes from 'prop-types';
import { useState } from 'react';
import { usePayingAgencyFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * Component for creating or updating a paying agency.
 * @param {Object} props - The props object.
 * @param {string} props.purpose - The purpose of the operation (e.g., 'create' or 'update').
 * @returns {JSX.Element} - JSX for creating or updating a paying agency.
 */

export const CreateUpdatePayingAgency = ({ purpose }) => {
  const [formValues, setFormValues] = useState(null);
  const [transmissionCodeRule, setTransmissionCodeRule] = useState([
    { required: true },
    { len: 9 }
  ]);

  const { payingAgencyfields } = usePayingAgencyFields(
    purpose,
    formValues,
    transmissionCodeRule,
    setTransmissionCodeRule
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setTransmissionCodeRule([
          { required: true },
          { len: data?.type?.startsWith('AMO') ? 9 : 8 }
        ]);
        return {
          ...data
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        address:
          data?.follow_up_prodcedure === 'COURRIER POSTAL' ? data?.address : {},
        procedure_url:
          data?.follow_up_prodcedure === 'FORMULAIRE EN LIGNE'
            ? data?.procedure_url
            : null,
        procedure_email:
          data?.follow_up_prodcedure === 'MAIL' ? data?.procedure_email : null
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        address:
          data?.follow_up_prodcedure === 'COURRIER POSTAL' ? data?.address : {},
        procedure_url:
          data?.follow_up_prodcedure === 'FORMULAIRE EN LIGNE'
            ? data?.procedure_url
            : null,
        procedure_email:
          data?.follow_up_prodcedure === 'MAIL' ? data?.procedure_email : null
      })
    }
  };
  return (
    <CreateUpdateContainer
      fields={payingAgencyfields}
      loadingFields
      purpose={purpose}
      baseUrl="payingagency"
      resource="payingagencies"
      withFilesManager={false}
      config={config}
      isFormData
      needFormValues
      setFormValues={setFormValues}
    />
  );
};

CreateUpdatePayingAgency.propTypes = {
  purpose: PropTypes.string.isRequired
};

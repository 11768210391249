/**
 * Array containing header configurations for displaying center data in a table or CSV file.
 * Each header object includes a label and a key corresponding to a specific property of the center data.
 */
export const headers = [
  {
    label: 'soldto',
    key: 'soldto'
  },
  {
    label: 'center_name',
    key: 'center_name'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'finess_number',
    key: 'finess_number'
  },
  {
    label: 'address.number',
    key: 'address.number'
  },
  {
    label: 'address.street',
    key: 'address.street'
  },
  {
    label: 'address.city',
    key: 'address.city'
  },
  {
    label: 'address.postal_code',
    key: 'address.postal_code'
  },
  {
    label: 'sales_representative',
    key: 'sales_representative'
  },
  {
    label: 'regional_director',
    key: 'regional_director'
  }
];

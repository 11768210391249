/**
 * Returns the formatted phone number by using only the provided number.
 *
 * @function
 * @param {Object} phoneNumber - The phone number object. (ex. { country_code: '+33', number: '0612345678' })
 * @param {string} phoneNumber.number - The phone number. (ex. '0612345678')
 * @returns {string} The formatted phone number. (ex. '0612345678') if phone number is valid or '-' if no phone number is provided.
 */
export const getPhoneNumber = (phoneNumber) => {
  if (!phoneNumber || typeof phoneNumber !== 'object') return '-';
  const { number = null } = phoneNumber;
  if (!number) return '-';
  return number;
};

import { useTranslation } from 'react-i18next';
import moment from 'moment';

/**
 * Custom hook that generates a content list for an invoice.
 * This hook takes data related to an invoice and returns an array of objects representing the content of the invoice.
 * @hook
 * @param {Object} data - Data related to the invoice.
 * @param {string} data.registered_invoicing_date - The registered invoicing date of the invoice.
 * @param {string} data.last_communication_date - The last communication date related to the invoice.
 * @param {string} data.invoicing_start_date - The start date of invoicing for the invoice.
 * @param {string} data.invoicing_end_date - The end date of invoicing for the invoice.
 * @param {string} data.invoicing_forecast_date - The forecast date of invoicing for the invoice.
 * @param {string} data.lpp_code - The LPP (Local Professional Pensions) code associated with the invoice.
 * @param {string} data.lpp_amount - The amount related to LPP for the invoice.
 * @param {Object} data.payingagency_amo - Object containing information about the agency responsible for AMO (Medicalized Assistance for Work).
 * @param {string} data.payingagency_amo.name_agency - The name of the agency responsible for AMO.
 * @param {string} data.amo_code - The code associated with AMO for the invoice.
 * @param {string} data.risk - The risk associated with the invoice.
 * @param {string} data.exemption - The exemption status related to the invoice.
 * @param {string} data.ame - The AME (Medical Assistance State) related to the invoice.
 * @param {Object} data.payingagency_amc - Object containing information about the agency responsible for AMC (Medicalized Assistance for Children).
 * @param {string} data.payingagency_amc.name_agency - The name of the agency responsible for AMC.
 * @param {string} data.amc_name - The name of the AMC related to the invoice.
 * @param {string} data.amc_code - The code associated with AMC for the invoice.
 * @returns {Array} A content list for the invoice.
 */

export const useListContentInvoice = (data = {}) => {
  const { t } = useTranslation();
  const translationKey = 'patients.form';

  const {
    registered_invoicing_date,
    last_communication_date,
    invoicing_start_date,
    invoicing_end_date,
    invoicing_forecast_date,
    lpp_code,
    lpp_amount,
    payingagency_amo,
    risk,
    exemption,
    ame,
    payingagency_amc,
    amc
  } = data;

  return [
    {
      label: t(`${translationKey}.registered_invoicing_date`),
      span: 1,
      content: registered_invoicing_date
        ? moment(registered_invoicing_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t(`${translationKey}.last_communication_date`),
      span: 1,
      content: last_communication_date
        ? moment(last_communication_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t(`${translationKey}.invoicing_start_date`),
      span: 1,
      content: invoicing_start_date
        ? moment(invoicing_start_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t(`${translationKey}.invoicing_end_date`),
      span: 1,
      content: invoicing_end_date
        ? moment(invoicing_end_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t(`${translationKey}.invoicing_forecast_date`),
      span: 1,
      content: invoicing_forecast_date
        ? moment(invoicing_forecast_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t(`${translationKey}.lpp_code`),
      span: 1,
      content: lpp_code || '-'
    },
    {
      label: t(`${translationKey}.lpp_amount`),
      span: 1,
      content: lpp_amount || '-'
    },
    {
      label: t(`${translationKey}.amo_name`),
      span: 1,
      content: payingagency_amo?.name_agency || '-'
    },
    {
      label: t(`${translationKey}.amo_code`),
      span: 1,
      content:
        payingagency_amo?.transmission_code.replace(
          /^(\d{2})(\d{3})(\d{4})$/,
          '$1 $2 $3'
        ) || '-'
    },
    {
      label: t(`${translationKey}.risk`),
      span: 1,
      content: risk ? t(`enums.${risk}`) : '-'
    },
    {
      label: t(`${translationKey}.exemption`),
      span: 1,
      content: exemption || '-'
    },
    {
      label: t(`${translationKey}.ame`),
      span: 1,
      content: ame ? t('breadcrumbs.yes') : t('breadcrumbs.no') || '-'
    },
    {
      label: t(`${translationKey}.amc`),
      span: 1,
      content: amc ? t('breadcrumbs.yes') : t('breadcrumbs.no')
    },
    {
      label: t(`${translationKey}.payingagency_amc_name`),
      span: 1,
      content: payingagency_amc?.name_agency || '-'
    },
    {
      label: t(`${translationKey}.amc_code`),
      span: 1,
      content: payingagency_amc?.transmission_code || '-'
    }
  ];
};

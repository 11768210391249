import { Route, Routes } from 'react-router-dom';
import { ListCenters } from './ListCenters';
import { CreateUpdateCenter } from './CreateUpdateCenter';
import { ShowCenter } from './ShowCenter';
import { Exception } from '../../components';

/**
 * Router component for managing center-related routes.
 * This component defines routes for creating, updating, showing, and listing centers.
 * @component
 * @returns {JSX.Element} The JSX element representing the defined routes.
 */
export const CenterRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateCenter purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateCenter purpose="edit" />} />
    <Route path="/show/:id" element={<ShowCenter />} />
    <Route index element={<ListCenters />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Input, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';

/**
 * Component for rendering the forgot password form.
 * This component allows users to request a password reset email by providing their username/email.
 * @component
 * @param {Function} switchForm - Function to switch to another form (e.g., login form).
 * @returns {JSX.Element} ForgotPwdForm component.
 */
const ForgotPwdForm = ({ switchForm }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const askForPwdReset = async (email) => {
    setLoading(true);
    try {
      await dispatchAPI('GET', { url: `reset-pwd/${email}` });
      message.success(t('login.resetPwdEmailSent', { email }));
      switchForm('login');
      navigate(outOfNavRoutes.LOGIN);
    } catch (e) {
      message.error(t('login.resetPwdEmailError'));
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await askForPwdReset(values.username.toLowerCase());
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.username')}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.resetPwd')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ width: '100%' }}
          onClick={() => switchForm('login')}
        >
          {t('buttons.back')}
        </Button>
      </Form.Item>
    </Form>
  );
};

ForgotPwdForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default ForgotPwdForm;

import { Route, Routes } from 'react-router-dom';
import { ListPayingAgency } from './ListPayingAgency';
import { CreateUpdatePayingAgency } from './CreateUpdatePayingAgency';
import { ShowPayingAgency } from './ShowPayingAgency';
import { Exception } from '../../components';

export const PayingAgencyRouteur = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdatePayingAgency purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdatePayingAgency purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowPayingAgency />} />
    <Route index element={<ListPayingAgency />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);

import { useTranslation } from 'react-i18next';
import { Card, Flex } from 'antd';

/**
 * Component for displaying folder status statistics.
 * @param {Object[]} totals - An array containing objects representing the totals.
 * @param {string} totals[].type - The type of total.
 * @param {number} totals[].count - The count of items.
 * @param {number} totals[].total - The total count.
 * @returns {JSX.Element} - JSX for displaying folder status statistics.
 */

export const FolderStatusStats = (totals) => {
  const { t } = useTranslation();

  return (
    <Flex
      justify="center"
      gap="large"
      wrap
      style={{ marginTop: 16, width: '100%' }}
    >
      {(totals?.totals || []).map((total) => (
        <Card className="statistics-card">
          <p className="statistics-card-title">
            {t(`patients.columns.totals.${total.type}`)}
          </p>
          <p className="statistics-card-percentage">{`${(
            (total.count / total.total) *
            100
          ).toFixed(2)}%`}</p>
          <p className="statistics-card-count">{`${total.count} / ${total.total}`}</p>
        </Card>
      ))}
    </Flex>
  );
};

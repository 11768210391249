import { Badge, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const CommentsButton = ({ count, showDrawer }) => {
  const { t } = useTranslation();

  return (
    <Badge count={count} overflowCount={99}>
      <Button type="primary" onClick={showDrawer}>{`${t(
        'centers.addCommentButton'
      )} `}</Button>
    </Badge>
  );
};

CommentsButton.propTypes = {
  count: PropTypes.string.isRequired,
  showDrawer: PropTypes.func.isRequired
};

import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

export const useErrorMessage = () => {
  const { t } = useTranslation();
  return {
    message: (
      status = '404',
      duration = 4.5,
      key = undefined,
      notificationType = 'error'
    ) => {
      const s = status?.response?.status || status;
      const regex = /{([^:]+):/;
      const duplicateKey =
        status?.response?.data?.message?.match(regex) &&
        status?.response?.data?.message?.match(regex)[1]?.trim();

      if (duplicateKey) {
        notification[notificationType]({
          message: t(`errors.message.duplicate.${duplicateKey}`),
          key: key || 'network_feedback',
          duration
        });
      } else {
        notification[notificationType]({
          message: t(`errors.message.${s}`),
          key: key || 'network_feedback',
          duration
        });
      }
    }
  };
};

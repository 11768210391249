import React from 'react';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for listing mails.
 * @returns {JSX.Element} ListMails component.
 */
const ListMails = () => {
  const { user } = useAuthContext();
  const { columns } = useColumns();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  return (
    <ListResource
      resourceName="mails"
      columns={columns}
      editAction={false}
      deleteAction={isAdmin}
      withCreateButton={false}
      headless={false}
      withSearchBar={false}
    />
  );
};

export default ListMails;

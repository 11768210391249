import { useState, useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

export const useFields = (purpose, centers) => {
  const { user } = useAuthContext();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [rppsNumber, setRppsNumber] = useState([]);

  const onsearch = (input, option) => {
    const optionText = option.props.children;
    const normalizedText = optionText.toString().toLowerCase();
    return normalizedText.includes(input.toLowerCase());
  };

  const getPrescribers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prescribers' });

      const rppsNumberValues = data.map((item) => {
        if (item._id !== id) {
          return item.rpps_number;
        }
        return [];
      });

      setRppsNumber(rppsNumberValues);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const validateRpps = (_, value) => {
    const isRpps = rppsNumber?.some((s) => s === value);
    if (isRpps) {
      return Promise.reject(new Error(t(`prescribers.form.error.rppsExist`)));
    }
    return Promise.resolve();
  };

  useEffect(async () => {
    setIsFieldsLoading(false);
    await getPrescribers();
  }, []);

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ type: 'email' }]
    },
    {
      label: 'phone_number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['rpps_number'],
      rules: [
        { len: 11 },
        {
          validator: validateRpps,
          required: true
        }
      ],
      input: <Input type="number" />
    },
    {
      name: ['health_insurance_id'],
      input: (
        <Form.List name="health_insurance_id" initialValue={['']}>
          {(fieldsComment, { add, remove }) => (
            <>
              {fieldsComment.map((field) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name]}
                    name={[field.name]}
                    rules={[
                      {
                        len: 9,
                        message: t(
                          'prescribers.form.error_length_health_insurance_id'
                        )
                      }
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => isAdmin && remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => isAdmin && add()}>
                  {t('prescribers.form.addInsurance_id')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    ...(centers?.length > 0
      ? [
          {
            name: ['linked_center'],
            rules: [{ required: true }],
            input: (
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
              >
                {(centers || []).map((center) => (
                  <Option key={center._id} value={center._id}>
                    {`${center?.center_name} (${center?.soldto})`}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : [])
  ];

  return {
    fields,
    isFieldsLoading
  };
};

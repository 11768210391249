import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('prescribers.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('prescribers.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('prescribers.form.health_insurance_id'),
      key: 'health_insurance_id',
      dataIndex: 'health_insurance_id',
      sorter: true,
      render: (health_insurance_ids) => (
        <div>{health_insurance_ids.join(', ')}</div>
      )
    },

    {
      title: t('prescribers.form.rpps_number'),
      key: 'rpps_number',
      dataIndex: 'rpps_number',
      sorter: true
    }
  ];
};

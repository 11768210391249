import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/formatters';

export const useColumns = () => {
  const { t } = useTranslation();

  /**
   * Custom hook that generates column configuration for center contact data table.
   * This hook provides column definitions for displaying contact-related information of centers in a table.
   * @hook
   * @returns {Array} An array of column configurations for the center contact data table.
   */
  return [
    {
      title: t('centers.form.contact.position'),
      key: 'position',
      dataIndex: 'position',
      render: (position) => position || '-',
      sorter: true
    },
    {
      title: t('centers.form.contact.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: (last_name) => last_name || '-',
      sorter: true
    },
    {
      title: t('centers.form.contact.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      render: (first_name) => first_name || '-',
      sorter: true
    },
    {
      title: t('centers.form.contact.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => getPhoneNumber(phone_number),
      sorter: true
    },
    {
      title: t('centers.form.contact.email'),
      key: 'email',
      dataIndex: 'email',
      render: (email) => email || '-',
      sorter: true
    }
  ];
};

import { useTranslation } from 'react-i18next';
import moment from 'moment';

/**
 * Custom hook that generates a content list for the Early Credit Repayment (ECR).
 * This function utilizes `useTranslation` for internationalization and `moment` for date formatting.
 *
 * @param {Object} data - The data required to generate the content list.
 * @param {string} data.switch_third_part_rac_date - The ECR date that may be formatted.
 * @param {string} data.switch_third_part_rac_reason - The reason for the ECR, used to retrieve the translation.
 * @param {number|string} data.rac_amount - The amount of the ECR.
 *
 * @returns {Array<Object>} A list of objects describing each content item of the ECR. Each object contains the keys `label`, `span`, and `content`.
 *                          `label` is a translated string, `span` is an integer indicating the relative width of the item in the interface,
 *                          and `content` is the formatted value of the corresponding information.
 *
 * @hook
 */
export const useListContentRac = (data = {}) => {
  const { t } = useTranslation();
  const translationKey = 'patients.form';

  const {
    switch_third_part_rac_date,
    switch_third_part_rac_reason,
    rac_amount
  } = data;
  return [
    {
      label: t(`${translationKey}.switch_third_part_rac_date`),
      span: 1,
      content: switch_third_part_rac_date
        ? moment(switch_third_part_rac_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t(`${translationKey}.switch_third_part_rac_reason`),
      span: 1,
      content: switch_third_part_rac_reason
        ? t(`enums.${switch_third_part_rac_reason}`)
        : '-'
    },
    {
      label: t(`${translationKey}.rac_amount`),
      span: 1,
      content: rac_amount || '-'
    }
  ];
};

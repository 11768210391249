import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../prescribers/fields';
import { useCenterFields } from '../centers/fields';
import usePatientContext from './PatientContext';

/**
 * Component for displaying a drawer for creating or updating centers or prescribers.
 * @param {Object} props - The props object.
 * @param {boolean} props.isDrawerOpen - Indicates whether the drawer is open or not.
 * @param {string} props.model - The model type ('centers' or 'prescribers').
 * @param {string} [props.purpose] - The purpose of the operation (e.g., 'create' or 'update').
 * @param {Function} props.closeDrawer - Function to close the drawer.
 * @param {Function} [props.getPrescribers] - Function to get prescribers data.
 * @returns {JSX.Element} - JSX for displaying the drawer for creating or updating centers or prescribers.
 */

const CenterPrescriberDrawer = ({
  isDrawerOpen,
  model,
  purpose,
  closeDrawer
}) => {
  const { t } = useTranslation();
  const { getCenters, centers, prescribers, getPrescribers } =
    usePatientContext();

  const { fields } = useFields(purpose, centers);
  const { centerFields } = useCenterFields(purpose, prescribers, true);
  const selectedFields = model.trim() === 'centers' ? centerFields : fields;

  const customNavigate = () => {
    closeDrawer();
    getCenters();
    getPrescribers();
  };

  return (
    <Drawer
      centered
      title={t(`${model}.titles.${purpose}`)}
      open={isDrawerOpen}
      onClose={closeDrawer}
      width={1200}
      footer={false}
    >
      {model && (
        <CreateUpdateContainer
          fields={selectedFields}
          purpose={purpose}
          baseUrl={model}
          resource={model}
          isOverlay
          customNavigate={customNavigate}
          withHeader={false}
          withFilesManager={false}
          needFormData={false}
        />
      )}
    </Drawer>
  );
};

/**
 * PropTypes for CenterPrescriberDrawer component.
 * @type {Object}
 * @property {boolean} isDrawerOpen - Indicates whether the drawer is open or not.
 * @property {string} model - The model type ('centers' or 'prescribers').
 * @property {string} [purpose] - The purpose of the operation (e.g., 'create' or 'update').
 * @property {Function} closeDrawer - Function to close the drawer.
 * @property {Function} [getPrescribers] - Function to get prescribers data.
 */

CenterPrescriberDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  model: PropTypes.string.isRequired,
  purpose: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired
};

/**
 * Default props for CenterPrescriberDrawer component.
 * @type {Object}
 * @property {string} [purpose=null] - The purpose of the operation (e.g., 'create' or 'update').
 * @property {Function} [getPrescribers=null] - Function to get prescribers data.
 */

CenterPrescriberDrawer.defaultProps = {
  purpose: null
};

export default CenterPrescriberDrawer;

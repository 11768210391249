import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Card, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { CommentsDrawer } from '../../components/CommentsDrawer/CommentsDrawer';
import { CommentsButton } from '../../components/CommentsButton/CommentsButton';

/**
 * Component for displaying details of a paying agency.
 * @returns {JSX.Element} - JSX for displaying details of a paying agency.
 */

export const ShowPayingAgency = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [payingagency, setPayingagency] = useState();
  const listContent = useListContent(payingagency);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [form] = Form.useForm();
  const [formEditComment] = Form.useForm();

  const getPayingAgency = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/payingagency/${id}?populate=comments.author`
      });
      setPayingagency(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deletePayingAgency = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/payingagency/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/payingagency/comments/${id}`,
        body
      });
      form.resetFields();
      getPayingAgency();
    } catch (e) {
      message(e);
    }
  };

  const handleEditValidateClick = async (body, editingKey) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/payingagency/comments/${id}/${editingKey}`,
        body
      });
      formEditComment.resetFields();
      getPayingAgency();
    } catch (e) {
      message(e);
    }
  };

  const deleteResource = async (editingKey) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/payingagency/comments/${id}/${editingKey}`
      });
      formEditComment.resetFields();
      getPayingAgency();
    } catch (e) {
      message(e);
    }
  };

  const showDrawer = () => {
    setSize('large');
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getPayingAgency();
    })();
  }, [getPayingAgency]);

  return (
    <>
      <ContentCustom>
        <PageHeaderCustom
          title={payingagency?.name_agency}
          extra={
            <>
              <CommentsButton
                count={payingagency?.comments?.length}
                showDrawer={showDrawer}
              />
              <Link to={{ pathname: `${routes.PAYING_AGENCIES}/edit/${id}` }}>
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deletePayingAgency}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          }
        />

        <Row gutter={[0, 24]}>
          <Col xs={24}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card title={payingagency?.type || '-'}>
                <DescriptionList data={listContent} translate />
              </Card>
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
      <CommentsDrawer
        title={t('payingagencies.drawer.addComment.title')}
        onClose={onClose}
        open={open}
        size={size}
        updateResource={updateResource}
        deleteResource={deleteResource}
        handleEditValidateClick={handleEditValidateClick}
        form={form}
        formEditComment={formEditComment}
        models={payingagency}
      />
    </>
  );
};

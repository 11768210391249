import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/formatters';

/**
 * Custom hook that generates column configuration for center contact data table.
 * This hook provides column definitions for displaying contact-related information of centers in a table.
 * It includes columns for first name, last name, phone number, and email.
 * @hook
 * @returns {Array} An array of column configurations for the center contact data table.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('centers.form.contact.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: (last_name) => last_name || '-',
      sorter: true
    },
    {
      title: t('centers.form.contact.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      render: (first_name) => first_name || '-',
      sorter: true
    },
    {
      title: t('centers.form.contact.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => getPhoneNumber(phone_number),
      sorter: true
    },
    {
      title: t('centers.form.contact.email'),
      key: 'email',
      dataIndex: 'email',
      render: (email) => email || '-',
      sorter: true
    },
    {
      title: t('centers.form.contact.health_insurance_id'),
      key: 'health_insurance_id',
      dataIndex: 'health_insurance_id',
      render: (health_insurance_id) => health_insurance_id || '-',
      sorter: true
    }
  ];
};

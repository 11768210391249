import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const useListContentCenter = (data = {}) => {
  const { t } = useTranslation();
  const translationKey = 'centers.form';

  const { center, prescriber, prescription_date_1, prescription_date_2 } = data;

  return [
    {
      label: t(`${translationKey}.soldto`),
      span: 1,
      content: center?.soldto || '-'
    },
    {
      label: t(`${translationKey}.center_name`),
      span: 1,
      content: center?.center_name || '-'
    },
    {
      label: t(`${translationKey}.type`),
      span: 2,
      content: center?.type ? t(`${translationKey}.enums.${center?.type}`) : '-'
    },
    {
      label: t(`${translationKey}.finess_number`),
      span: 1,
      content: center?.finess_number || '-'
    },
    {
      label: t(`${translationKey}.address.street`),
      span: 1,
      content:
        center?.address && center?.address?.street && center?.address?.number
          ? `${center?.address.number}, ${center?.address.street}`
          : '-'
    },
    {
      label: t(`${translationKey}.address.postal_code`),
      span: 1,
      content: center?.address?.postal_code || '-'
    },
    {
      label: t(`${translationKey}.address.city`),
      span: 1,
      content: center?.address?.city || '-'
    },
    {
      label: t(`${translationKey}.sales_representative`),
      span: 1,
      content: center?.sales_representative || '-'
    },
    {
      label: t(`${translationKey}.regional_director`),
      span: 1,
      content: center?.regional_director || '-'
    },
    {
      label: t(`${translationKey}.prescriber.last_name_prescriber`),
      span: 1,
      content: prescriber?.last_name || '-'
    },
    {
      label: t(`${translationKey}.prescriber.first_name_prescriber`),
      span: 1,
      content: prescriber?.first_name || '-'
    },
    {
      label: t(`${translationKey}.prescriber.rpps_number`),
      span: 1,
      content: prescriber?.rpps_number || '-'
    },
    {
      label: 'prescribers.form.health_insurance_id',
      span: 1,
      content:
        prescriber?.health_insurance_id
          ?.map((insurance_id) => insurance_id)
          .join('/') || '-'
    },
    {
      label: t(`${translationKey}.prescription_date_1`),
      span: 1,
      content: prescription_date_1
        ? moment(prescription_date_1).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t(`${translationKey}.prescription_date_2`),
      span: 1,
      content: prescription_date_2
        ? moment(prescription_date_2).format('DD/MM/YYYY')
        : '-'
    }
  ];
};

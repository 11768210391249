export const headers = [
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'rpps_number',
    key: 'rpps_number'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'country_code',
    key: 'country_code'
  },
  {
    label: 'phone_number',
    key: 'phone_number'
  },
  {
    label: 'health_insurance_id',
    key: 'health_insurance_id'
  }
];

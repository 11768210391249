import {
  HomeOutlined,
  UserOutlined,
  BranchesOutlined,
  SettingOutlined,
  MailOutlined,
  IdcardOutlined,
  SolutionOutlined,
  BankOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  PATIENTS: <IdcardOutlined />,
  PRESCRIPTIONS: <SolutionOutlined />,
  PRESCRIBERS: <SolutionOutlined />,
  CENTERS: <HomeOutlined />,
  PAYING_AGENCIES: <BankOutlined />,
  WORKFLOWS: <BranchesOutlined />,
  MAILS: <MailOutlined />,
  CONFIGURATIONS: <SettingOutlined />
};

export default navMenuLogos;

export const headers = [
  {
    label: 'created_at',
    key: 'created_at'
  },
  {
    label: 'registered_invoicing_date',
    key: 'registered_invoicing_date'
  },
  {
    label: 'latitude_number',
    key: 'latitude_number_fake'
  },
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'social_security_number',
    key: 'social_security_number'
  },
  {
    label: 'date_of_birth',
    key: 'date_of_birth'
  },
  {
    label: 'medical_device_reference',
    key: 'medical_device_reference'
  },
  {
    label: 'medical_device_serial_number',
    key: 'medical_device_serial_number'
  },
  {
    label: 'center.soldto',
    key: 'center.soldto'
  },
  {
    label: 'center.type',
    key: 'center.type'
  },
  {
    label: 'initial_prescription',
    key: 'initial_prescription'
  },
  {
    label: 'ald_prescription',
    key: 'ald_prescription'
  },
  {
    label: 'center.sales_representative',
    key: 'center.sales_representative'
  },
  {
    label: 'center.regional_director',
    key: 'center.regional_director'
  },
  {
    label: 'center.center_name',
    key: 'center.center_name'
  },
  {
    label: 'center.finess_number',
    key: 'center.finess_number'
  },
  {
    label: 'prescriber.first_name',
    key: 'prescriber.first_name'
  },
  {
    label: 'prescriber.last_name',
    key: 'prescriber.last_name'
  },
  {
    label: 'prescriber.health_insurance_id',
    key: 'prescriber.health_insurance_id'
  },
  {
    label: 'prescriber.rpps_number',
    key: 'prescriber.rpps_number'
  },
  {
    label: 'last_communication_date',
    key: 'last_communication_date'
  },
  {
    label: 'invoicing_start_date',
    key: 'invoicing_start_date'
  },
  {
    label: 'invoicing_end_date',
    key: 'invoicing_end_date'
  },
  {
    label: 'invoicing_forecast_date',
    key: 'invoicing_forecast_date'
  },
  {
    label: 'payingagency_amo.name_agency',
    key: 'payingagency_amo.name_agency'
  },
  {
    label: 'payingagency_amo.transmission_code',
    key: 'payingagency_amo.transmission_code'
  },
  {
    label: 'risk',
    key: 'risk'
  },
  {
    label: 'exemption',
    key: 'exemption'
  },
  {
    label: 'ame',
    key: 'ame'
  },
  {
    label: 'amc',
    key: 'amc'
  },
  {
    label: 'payingagency_amc.name_agency',
    key: 'payingagency_amc.name_agency'
  },
  {
    label: 'payingagency_amc.transmission_code',
    key: 'payingagency_amc.transmission_code'
  }
];

import { useState, useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

export const useFields = (purpose) => {
  const { user } = useAuthContext();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  useEffect(() => {
    setIsFieldsLoading(false);
  }, []);

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ type: 'email' }],
      disabled: purpose === 'edit'
    },
    {
      label: 'phone_number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['rpps_number'],
      rules: [{ len: 11 }],
      input: <Input type="number" />
    },
    {
      name: ['health_insurance_id'],
      input: (
        <Form.List name="health_insurance_id" initialValue={['']}>
          {(fieldsComment, { add, remove }) => (
            <>
              {fieldsComment.map((field) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name]}
                    name={[field.name]}
                    rules={[
                      {
                        len: 9,
                        message: t(
                          'prescribers.form.error_length_health_insurance_id'
                        )
                      }
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => isAdmin && remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => isAdmin && add()}>
                  {t('prescribers.form.addInsurance_id')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

import { useState } from 'react';
import dayjs from 'dayjs';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { FolderStatusStats } from './FolderStatusStats';

/**
 * Component for displaying a list of patients.
 * @returns {JSX.Element} - JSX for displaying a list of patients.
 */

export const ListPatients = () => {
  const columns = useColumns();
  const [totals, setTotals] = useState([]);

  const formatter = (data) => {
    const {
      payingagency_amc,
      health_insurance,
      initial_prescription,
      ald_prescription,
      ame,
      registered_invoicing_date
    } = data;
    return {
      ...data,
      registered_invoicing_date:
        registered_invoicing_date &&
        dayjs(registered_invoicing_date).format('DD/MM/YYYY'),
      created_at:
        payingagency_amc?.created_at &&
        dayjs(payingagency_amc?.payingagency_amc).format('DD/MM/YYYY'),
      date_of_birth:
        payingagency_amc?.date_of_birth &&
        dayjs(payingagency_amc?.date_of_birth).format('DD/MM/YYYY'),
      payingagency_amc: payingagency_amc?.transmission_code ? 'oui' : 'non',
      health_insurance: health_insurance.length > 0 ? 'oui' : 'non',
      initial_prescription: initial_prescription.length > 0 ? 'oui' : 'non',
      ald_prescription: ald_prescription === 'true' ? 'oui' : 'non',
      ame: ame ? 'oui' : 'non'
    };
  };

  return (
    <ListResource
      resourceName="patients"
      columns={columns}
      populate="center,prescriber,payingagency_amo,payingagency_amc"
      headers={headers}
      resourceModelName="Patient"
      setTotals={setTotals}
      formatter={formatter}
      // eslint-disable-next-line react/no-children-prop
      children={<FolderStatusStats totals={totals} />}
    />
  );
};

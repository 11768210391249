import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';

/**
 * Component for displaying a list of prescribers.
 * @returns {JSX.Element} - JSX for displaying a list of prescribers.
 */

export const ListPrescribers = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="prescribers"
      columns={columns}
      headers={headers}
      resourceModelName="Prescriber"
    />
  );
};

import { Route, Routes } from 'react-router-dom';
import { PatientContextProvider } from './PatientContext';
import { ListPatients } from './ListPatients';
import { CreateUpdatePatient } from './CreateUpdatePatient';
import { ShowPatient } from './ShowPatient';
import { Exception } from '../../components';

export const PatientRouter = () => (
  <PatientContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdatePatient purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdatePatient purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowPatient />} />
      <Route index element={<ListPatients />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </PatientContextProvider>
);

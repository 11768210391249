export const headers = [
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'name_agency',
    key: 'name_agency'
  },
  {
    label: 'transmission_code',
    key: 'transmission_code'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'phone_number.country_code',
    key: 'phone_number.country_code'
  },
  {
    label: 'phone_number.number',
    key: 'phone_number.number'
  },
  {
    label: 'follow_up_prodcedure',
    key: 'follow_up_prodcedure'
  }
];
